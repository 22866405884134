// project import
// import pages from './pages'
import dashboard from './dashboard'
import member from './member'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, member]
}

export default menuItems
