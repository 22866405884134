export const USER_SIGNIN_REQUEST = 'USER_SIGNIN_REQUEST'
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS'
export const USER_SIGNIN_FAIL = 'USER_SIGNIN_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'


export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_ACTIVE = 1
export const ACCESS_TOKEN = 'access_token'

export const TREATMENT_SERVICEID = 1
export const PRODUCT_SEREVICEID = 2
export const DEFAULT_INSURANCE_COMPANY = 1
export const USER_SETTING_SHIFT = 'shift'


